import { Config } from "../config";
import { EVENTS } from "../constants/events";
import { Iframe } from "./iframeService";

const URL = Config.WidgetUrl();

export class Message {
  private static instance: Message;

  constructor() {
    if (Message.instance) return Message.instance;

    window.addEventListener("message", Message.recieveMessage, {});

    Message.instance = this;

    return this;
  }

  static recieveMessage = (e: MessageEvent) => {
    const iframe = new Iframe();

    const { type, message } = Message.parseMessage(e.data) ?? {};

    const messageEventMap = {
      [EVENTS.MAXIMIZE]: iframe.changeWidgetSize,
      [EVENTS.COLLAPSE]: iframe.changeWidgetSize,
      [EVENTS.OPEN_IMAGE_VIEWER]: iframe.openImageModel,
      [EVENTS.CLOSE_IMAGE_VIEWER]: iframe.closedImageModel,
      [EVENTS.CLICK_CLOSE_BUTTON]: this.closeWidgetEvent,
      [EVENTS.REQUEST_WEBSITE_URL_EVENT]: this.getHostWebsiteURL,
      [EVENTS.CLOSE_PROMPT]: iframe.closePrompt,
    };

    messageEventMap[type as keyof typeof messageEventMap]?.({ message, type });
  };

  static closeWidgetEvent() {
    const iframe = new Iframe();

    iframe.launcher?.contentWindow?.postMessage(
      JSON.stringify({
        type: EVENTS.CLOSE_WIDGET_EVENT,
        message: EVENTS.CLOSE_WIDGET_EVENT,
      }),
      URL
    );
  }

  static getHostWebsiteURL() {
    const iframe = new Iframe();

    const websiteUrl = window.location.href;

    iframe.get?.contentWindow?.postMessage(
      JSON.stringify({
        type: EVENTS.SEND_WEBSITE_URL_EVENT,
        message: websiteUrl,
      }),
      URL
    );
  }

  static parseMessage(message: string) {
    try {
      return JSON.parse(message) as unknown as { message: any; type: any };
    } catch {}
  }
}
