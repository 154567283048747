import { Config } from "./config";
import { EVENTS } from "./constants/events";
import {
  __DEEPBOT__CONFIG__IFRAME_STYLE,
  __DEEPBOT__IFRAME_STYLE,
  __DEEPBOT__LAUNCH__IFRAME_STYLE,
} from "./constants/index";
import { Iframe } from "./services/iframeService";
import { Message } from "./services/messageService";
import { fancyConsole, fancyErrorConsole, throttle } from "./utils/index";

ready(() => setTimeout(init, 1000));

const url = Config.Url();

async function init() {
  try {
    const data = getChatbotData();

    validate({ chatbotId: data.chatbotId, userId: data.userId });

    initIframes()

    await waitUntilWidgetIsReady()

    const config = (await getConfig(data)) as DeepBot.Config;

    canShowBot(config);

    fancyConsole("Deepbot is running");

    new Message();

    const Ifrem = new Iframe();

    Ifrem.configureTheme(config.widgetData);

    if (Ifrem.launcher) Ifrem.launcher.style.display = "block";

    const getIfrem = Ifrem.get;

    let lastUrl = "";

    const observer = new MutationObserver(
      //@ts-check
      throttle(() => {
        const currentUrl = window.location.href;

        if (currentUrl === lastUrl) return;

        lastUrl = currentUrl;

        getIfrem?.contentWindow?.postMessage(
          JSON.stringify({
            type: EVENTS.GET_PAGEURL,
            message: {
              url: currentUrl,
              title: document.title,
              knowledgebaseId: data.chatbotId,
            },
          }),
          url
        );
      }, 10000)
    );

    observer.observe(document.body, { childList: true, subtree: true });

    showPrompt(config);
  } catch (err: any) {
    err.message && fancyErrorConsole(err.message);

    fancyErrorConsole("Deepbot is not running....");
  }
}

function ready(fn: () => void) {
  if (document.readyState !== "loading") {
    fn();
    return;
  }

  document.addEventListener("DOMContentLoaded", fn);
}

function validate({
  chatbotId,
}: // userId,
{
  chatbotId?: string;
  userId?: string;
}) {
  if (!chatbotId) throw new Error("Chat bot id is not found");

  return true;
}

function waitUntilWidgetIsReady() {
  return new Promise((resolve, reject) => {

    const handleMessage = function (event: MessageEvent) {
      const data = Message.parseMessage(event.data);

      if (data && data.type === EVENTS.WIDGET_READY) {
        window.removeEventListener("message", handleMessage);

        resolve(data.message);
      }
    };

    window.addEventListener("message", handleMessage);

    setTimeout(() => {
      window.removeEventListener("message", handleMessage);

      reject(new Error("Timeout reached while waiting for"));
    }, 60000);

    
  });
}

function initIframes(){

  new Iframe();

}

function getConfig(data: any) {
  return new Promise((resolve, reject) => {
    const Ifrem = new Iframe();

  Ifrem.configureOrigin();



    const handleMessage = function (event: MessageEvent) {
      const data = Message.parseMessage(event.data);

      if (data && data.type === EVENTS.CONFIG_RESPONSE) {
        window.removeEventListener("message", handleMessage);

        resolve(data.message);
      }
    };

    window.addEventListener("message", handleMessage);

    setTimeout(() => {
      window.removeEventListener("message", handleMessage);

      reject(new Error("Timeout reached while waiting for"));
    }, 30000);

      Ifrem.getConfig(data);
  });
}

function canShowBot(config: DeepBot.Config): boolean {
  console.log(config);
  return true;
}

function getChatbotData(): { [k: string]: string | undefined } {
  const dataset = Object.fromEntries(
    Object.entries(
      (document.getElementById("__deepBotSdk__") as HTMLScriptElement)?.dataset
    )
  );

  return dataset;
}

function showPrompt(config: DeepBot.Config) {
  if (!config.widgetData.promptEnabled) return;

  const framesService = new Iframe();

  framesService.promptTimeout = setTimeout(
    framesService.showPrompt,
    config?.widgetData?.promptTriggerTimeout ?? 5000
  );
}
