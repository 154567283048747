export const EVENTS = {
  MAXIMIZE: "deepbot:deepbot_expand",
  MINIMIZE: "deepbot:deepbot_minimize",
  COLLAPSE: "deepbot:deepbot_collapse",
  CONFIG_RESPONSE: "deepbot:deepbot_config",
  GET_CONFIG: "deepbot:deepbot_get_config",
  GET_PAGEURL: "deepbot:deepbot_get_page_url",
  CREATE_CUSTOMER: "deepbot:deepbot_create_customer",
  CREATE_CUSTOMER_RESPONSE: "deepbot:deepbot_create_customer_response",
  CLICK_CLOSE_BUTTON: "deepbot:deepbot_click_close_button",
  CLOSE_WIDGET_EVENT: "deepbot:deepbot_close_widget_event",
  REQUEST_WEBSITE_URL_EVENT: "deepbot:deepbot_request_site",
  SEND_WEBSITE_URL_EVENT: "deepbot:deepbot_send_site",
  SET_ORIGIN_URL_EVENT: "deepbot:deepbot_SET_ORIGIN_URL",
  OPEN_IMAGE_VIEWER: "deepbot:deepbot_open_image_viewer",
  CLOSE_IMAGE_VIEWER: "deepbot:deepbot_close_image_viewer",
  SEND_IMAGE_URL: "deepbot:deepbot_send_image_url",
  WIDGET_DATA: "deepbot:deepbot_widget_data",
  OPEN_PROMPT: "deepbot:deepbot_open_prompt",
  CLOSE_PROMPT: "deepbot:deepbot_close_prompt",
  WIDGET_READY: "deepbot:deepbot_widget_is_ready",

} as const;
